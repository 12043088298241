<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<button [disabled]="isLoading() || !params" *ngIf="!autoLoading && !loaded" (click)="load()">
    <span *ngIf="!(isLoading() || !params)" class="right-icon">Show preview<i class="fas fa-search"></i></span>
    <span *ngIf="isLoading() || !params" class="loader right-icon">Loading<i class="fa fa-cog fa-spin"></i></span>
</button>
<div [hidden]="!loaded || !params" class="preview papaya" data-params="params"></div> 