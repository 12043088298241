<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<ng-container *ngIf="mode == 'view'">
    set
    <span class="var">{{fieldLabel ? fieldLabel : assignment?.field}}</span>
    =
    <span class="value">
        <span *ngIf="assignment?.type == 'string' && valueIsString == true">"</span>{{valueLabel ? valueLabel : assignment?.label}}<span *ngIf="assignment?.type == 'string' && valueIsString == true">"</span>
    </span>
</ng-container>
<ng-container *ngIf="mode != 'view'">
    set
    <select-box 
            class="var auto-width" 
            [class.error]="fieldError"
            [(ngModel)]="assignment.field" 
            (userChange)="onChangeField($event)" 
            (selectOption)="onSelectFieldOption($event)" 
            (deSelectOption)="onDeSelectFieldOption($event)" 
            [options]="fieldOptions" 
            placeholder="select field"
            (focusout)="fieldTouched = true"
            [clear]="false">
    </select-box>
    =
    <auto-ajust-input 
            *ngIf="!assigmentOptions" 
            class="value"
            [class.error]="valueError"
            [(ngModel)]="assignment.value" 
            placeholder="enter value" 
            (change)="onChangeValue()"
            (focusout)="valueTouched = true"
            [disabled]="!assignment.field">
    </auto-ajust-input>
    <select-box 
            *ngIf="assigmentOptions" 
            class="value auto-width"
            [class.error]="valueError"
            [(ngModel)]="assignment.value"
            [options]="assigmentOptions" 
            (userChange)="onChangeValue()" 
            [placeholder]="assignment.value"
            (focusout)="valueTouched = true"
            [clear]="false">
    </select-box>
</ng-container>
<span *ngIf="mode != 'view'" class="delete" (click)="delete.emit()"><i class="far fa-times-circle"></i></span>
<div class="error-msg" *ngIf="badValueRef"><i class="fas fa-exclamation-triangle"></i>Bad reference for this action, please update the value field. <br/>Given value : "
    <span class="hint" *ngIf="assignment && assignment.value && assignment.value['id']">Object with id = {{assignment.value['id']}} </span>
    <span class="hint" *ngIf="assignment && assignment.value && assignment.value['name']">and name = {{assignment.value['name']}} </span>
    <span class="hint" *ngIf="assignment && assignment.value && !assignment.value['id']">{{assignment.value}}</span>
"</div>