<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->
<div class="footer command-zone">
    <span [ngSwitch]="state.mode">
        <button type="button" *ngIf="state.backButton" i18n="View subject|Back button label@@backButton" class="Button left-icon" (click)="onBack()"><i class="fas fa-angle-left"></i>Back</button>
        <ng-template [ngSwitchCase]="'view'" *ngIf="state.canEdit">
                <button *ngIf="state.canEdit" type="button" i18n="View subject|Edit button label@@editButton" (click)="onEdit()" class="Button right-icon">Edit<i class="far fa-edit"></i></button>
                <button type="button" *ngIf="state.canDelete" i18n="Delete subject|Delete button label@@deleteButton" (click)="onDelete()" class="Button right-icon">Delete<i class="fa-regular fa-trash-can"></i></button>
        </ng-template>
        <ng-template [ngSwitchCase]="'edit'">
            <button type="button" (click)="onCancel()" class="Button right-icon">Cancel<i class="fas fa-ban"></i></button>
            <button type="button" i18n="Edit center|Update button label@@updateButton" (click)="onUpdate()" class="Button right-icon" [disabled]="!state.valid">
                Update<span *ngIf="!state.loading">
                    <i class="far fa-save"></i> 
                </span><span *ngIf="state.loading">
                    <i class="fa fa-cog fa-spin"></i> 
                </span>
            </button>
        </ng-template>
        <ng-template [ngSwitchCase]="'create'">
            <button type="button" i18n="Edit subject|Create button label@@createButton" (click)="onCreate()" class="Button right-icon" [disabled]="!state.valid">
                Save<span *ngIf="!state.loading">
                    <i class="far fa-save"></i>
                </span><span *ngIf="state.loading">
                    <i class="fa fa-cog fa-spin"></i>
                </span>
            </button>
        </ng-template>
        <ng-content></ng-content>
    </span>
</div>
<div class="spacer"></div>