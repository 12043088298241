<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->
<div [hidden]="!progressStatus?.progress">
    <progress-bar [progress]="progressStatus?.progress" [text]="'Preparing download'" [unknownDownload]="true"
        [class.error]="progressStatus?.status == -1" [class.done]="progressStatus?.status == 1"></progress-bar>
</div>
<node
        *ngIf="node"
        [class.selected]="menuOpened || treeService.isSelected(node.id, 'acquisition')"
        [label]="node.label"
        [clickable] = "node.id != null"
        awesome="fas fa-procedures"
        [(opened)]="node.opened"
        (labelClick)="menuOpened = withMenu && !menuOpened"
        [hasBox]="hasBox"
        [(ngModel)]="node.selected"
        (chkbxChange)="selectedChange.emit()"
        [route]="node.route"
        [hasChildren]="hasChildren()"
        (firstOpen)="loadDatasets()"
        [title]="node.title + ' n°' + node.id">

    <dropdown-menu [(openInput)]="menuOpened" *ngIf="menuOpened && withMenu && withMenu && node.id != null">
        <a [routerLink]="detailsPath + node.id" class="open-new-tab">
            <menu-item label="Details..." awesome="fa-regular fa-eye"></menu-item>
        </a>
            <menu-item [disabled]="(datasetIds && datasetIds.length == 0) || downloading" label="Download" awesome="fas fa-download" (click)="download()"></menu-item>
            <menu-item *ngIf="node.canDelete"  label="Delete" awesome="fa-regular fa-trash-can" (click)="deleteAcquisition()"></menu-item>
    </dropdown-menu>

    <ng-container *ngIf="node.open && node.datasets && node.datasets != $any('UNLOADED')">
        <dataset-node
            node
            *ngFor="let dsNode of node.datasets; let i = index"
            [input]="dsNode"
            [related]="node.id == null"
            (selectedChange)="selectedChange.emit()"
            [hasBox]="hasBox"
            (onDatasetDelete)="onDatasetDelete(i)"
            [withMenu]="withMenu"
        >
        </dataset-node>

    </ng-container>
</node>
