<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<ol>
	<li *ngIf="mode == 'edit' && !studyOptions" class="info-txt">
		<h3>Invite a new user</h3>
		Username or Email address : <input type="text" [(ngModel)]="invitationMail" placeholder="email address / username">
		<tool-tip>If an user with such email/username already exists, he will be directly added to the study, otherwise an email with an invitation will be sent.</tool-tip>
		<br/>Function in the study (researcher, mri manipulator, ...) : <input type="text" [(ngModel)]="invitationFunction" placeholder="function in the study">
		<br/><button type="button" class="invite-button right-icon" (click)="inviteUser()">Invite & Add<i class="fa-solid fa-plus"></i></button>
    </li>
	<li *ngIf="mode != 'view' && studyOptions">
		<label>List of studies</label>
		<span class="right-col">
			<select-box placeholder="Add a study ..." (onAddClick)="onStudyAdd($event)" [options]="studyOptions">
			</select-box>
		</span>
	</li>
	<li>
		<shanoir-table #memberTable
			[getPage]="getPage.bind(this)"
			[columnDefs]="columnDefs"
			(rowClick)="onUserClick($event)"
			[selectedId]="pannelStudyUser?.id">
		</shanoir-table>
		<span class="right-col" *ngIf="pannelStudyUser">
			<div class="pannel">
				<div class="close" (click)="closePannel()"><i class="fas fa-times"></i></div>
				<fieldset *ngIf="isAdmin">
					<legend>General</legend>
					<ul>
						<li>
							<label>Username</label>
							<span class="right-col">{{pannelStudyUser.userName}}</span>
						</li>
						<li>
							<label>First name</label>
							<span class="right-col">{{pannelStudyUser.user?.firstName}}</span>
						</li>
						<li>
							<label>Last name</label>
							<span class="right-col">{{pannelStudyUser.user?.lastName}}</span>
						</li>
						<li>
							<label>Email</label>
							<span class="right-col">{{pannelStudyUser.user?.email}}</span>
						</li>
						<li>
							<label>Receive Import Mail</label>
							<span class="right-col">
								<checkbox [mode]="mode == 'view' ? mode : 'edit'" [(ngModel)]="pannelStudyUser.receiveNewImportReport"></checkbox>
							</span>
						</li>
						<li>
							<label>Receive Member Mail</label>
							<span class="right-col">
								<checkbox [mode]="mode == 'view' ? mode : 'edit'" [(ngModel)]="pannelStudyUser.receiveStudyUserReport"></checkbox>
							</span>
						</li>
					</ul>
				</fieldset>
				<fieldset>
					<legend>General rights</legend>
					<ul>
						<li>
							<label>Role</label>
							<span class="right-col left-icon fix-icon user-icon">
								<i *ngIf="pannelStudyUser.user?.role?.id == 2" class="fas fa-user"></i>
								<i *ngIf="pannelStudyUser.user?.role?.id == 3" class="fas fa-user-tie"></i>
								<i *ngIf="pannelStudyUser.user?.role?.id == 1" class="fas fa-user-cog"></i>
								{{pannelStudyUser.user?.role?.displayName}}
							</span>
						</li>
						<li>
							<label>DUA</label>
							<span class="right-col fix-icon">
								<span *ngIf="pannelStudyUser.confirmed" class="bool-true"><i class="fas fa-check"></i></span>
								<span *ngIf="!pannelStudyUser.confirmed" class="bool-false"><i class="fas fa-times"></i></span>
							</span>
						</li>
						<li>
							<label>Can see all</label>
							<span class="right-col">
								<checkbox
									[mode]="mode == 'view' ? mode : 'edit'"
									[ngModel]="pannelStudyUser.studyUserRights.includes(StudyUserRight.CAN_SEE_ALL)"
									(onChange)="onEditRight(StudyUserRight.CAN_SEE_ALL, pannelStudyUser, $event)"
									[disabled]="pannelStudyUser.studyUserRights.includes(StudyUserRight.CAN_SEE_ALL) && pannelStudyUser.userId == getLoggedUserId()"
								></checkbox>
							</span>
						</li>
						<li>
							<label>Can download</label>
							<span class="right-col">
								<checkbox
									[mode]="mode == 'view' ? mode : 'edit'"
									[ngModel]="pannelStudyUser.studyUserRights.includes(StudyUserRight.CAN_DOWNLOAD)"
									(onChange)="onEditRight(StudyUserRight.CAN_DOWNLOAD, pannelStudyUser, $event)"
								></checkbox>
							</span>
						</li>
						<li>
							<label>Can import</label>
							<span class="right-col">
								<checkbox
									[mode]="mode == 'view' ? mode : 'edit'"
									[ngModel]="pannelStudyUser.studyUserRights.includes(StudyUserRight.CAN_IMPORT)"
									(onChange)="onEditRight(StudyUserRight.CAN_IMPORT, pannelStudyUser, $event)"
								></checkbox>
							</span>
						</li>
						<li>
							<label>Can administrate</label>
							<span class="right-col">
								<checkbox
									[mode]="mode == 'view' ? mode : 'edit'"
									[ngModel]="pannelStudyUser.studyUserRights.includes(StudyUserRight.CAN_ADMINISTRATE)"
									(onChange)="onEditRight(StudyUserRight.CAN_ADMINISTRATE, pannelStudyUser, $event)"
								></checkbox>
							</span>
						</li>
					</ul>
				</fieldset>
				<fieldset>
					<legend>Center rights</legend>
					<ul>
						<li class="all-centers" *ngIf="mode == 'edit' || pannelStudyUser.centers?.length == 0">
							<label>All centers (default)</label>
							<checkbox
								[mode]="mode == 'view' ? mode : 'edit'"
								[ngModel]="!(pannelStudyUser.centers?.length > 0)"
								(onChange)="onToggleAllCenters($event)"
							></checkbox>
						</li>
					</ul>
					<ul *ngIf="pannelStudyUser.centers?.length > 0">
						<li *ngFor="let center of centers">
							<label>{{center.name}}</label>
							<span class="right-col">
								<checkbox
									[mode]="mode == 'view' ? mode : 'edit'"
									[ngModel]="hasCenter(center, pannelStudyUser)"
									(onChange)="onEditCenter(center, pannelStudyUser, $event)"
								></checkbox>
							</span>
						</li>
					</ul>
				</fieldset>
			</div>
		</span>
	</li>
</ol>
