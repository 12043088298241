<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->
<node
        *ngIf="node"
        [class.selected]="menuOpened || treeService.isSelected(node.id, 'subject')"
        [label]="node.label"
        [tags]="node.tags"
        [qualityTag]="node.qualityTag"
        [awesome]="node.awesome"
        (firstOpen)="onFirstOpen()"
        [(opened)]="node.opened"
        [hasChildren]="hasChildren()"
        (labelClick)="menuOpened = withMenu && !menuOpened"
        [route]="node.route"
        [dataLoading]="loading"
        [title]="node.title + ' n°' + node.id">

    <dropdown-menu [(openInput)]="menuOpened" *ngIf="menuOpened && withMenu">
        <a [routerLink]="detailsPath" class="open-new-tab">
            <menu-item label="Details..." awesome="fa-regular fa-eye"></menu-item>
        </a>
      <menu-item [disabled]="loading" label="Download" awesome="fas fa-download" (click)="loading ? null : download()"></menu-item>
      <!-- <menu-item label="Collapse all" awesome="far fa-minus-square" (click)="collapseAll()"></menu-item> -->
    </dropdown-menu>

    <ng-container *ngIf="node.open && node.examinations && node.examinations != $any('UNLOADED')">
        <examination-node
                node
                *ngFor="let examinationNode of node.examinations;let i = index"
                [input]="examinationNode"
                (selectedChange)="selectedChange.emit()"
                [hasBox]="hasBox"
                (onExaminationDelete)="onExaminationDelete(i)"
                [withMenu]="withMenu"
        >
        </examination-node>
    </ng-container>
</node>
